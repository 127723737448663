<template #default>
	<li
		v-show="productsStore.products[index].Show"
		class="flex items-center justify-between flex-nowrap pt-3 mt-3"
	>
		<div class="flex items-center">
			<img
				:src="imageSrcFull"
				width="60"
				class="rounded-2xl object-fill"
			/>
			<div class="flex flex-col ml-3">
				<span class="md:text-md font-medium">{{
					productsStore.products[index].Name
				}}</span>
				<span class="text-xs font-medium">{{ UnitsQty }} icypoles</span>
			</div>
		</div>

		<div class="flex justify-center items-center mt-2 ml-3 select-none">
			<div class="flex items-baseline">
				<i
					@click="
						productsStore.DecreaseQty(
							productsStore.products[index].ProductId
						)
					"
					class="cursor-pointer px-2 text-md font-medium bg-gray-200 rounded-md"
				>
					<font-awesome-icon :icon="['fas', 'minus']"
				/></i>
				<input
					@click="productsStore.products[index].Qty = ''"
					inputmode="numeric"
					class="focus:outline-none bg-gray-100 border h-6 w-8 rounded text-sm mx-2 text-center"
					v-model="productsStore.products[index].Qty"
				/>

				<i
					@click="
						productsStore.IncreaseQty(
							productsStore.products[index].ProductId
						)
					"
					class="cursor-pointer mr-3 px-2 text-md font-medium bg-gray-200 rounded-md"
					><font-awesome-icon :icon="['fas', 'plus']"
				/></i>
			</div>

			<div class="mr-8">
				<span class="text-xs font-medium">Bags</span>
			</div>

			<div>
				<!-- <font-awesome-icon :icon="['fas', 'xmark']" /> -->
			</div>
		</div>
	</li>
	<textarea
		v-if="productsStore.products[index].Name == 'Mixed'"
		v-model="productsStore.products[index].Description"
		rows="2"
		class="my-3 block p-1 w-3/4 text-sm bg-gray-50 rounded-lg border border-gray-200 focus:ring-white focus:border-white dark:focus:ring-white dark:focus:border-white"
		placeholder="Mixed bags"
	></textarea>
</template>

<script>
	import { onMounted, ref } from 'vue'
	import { useProductsStore } from '../stores/products'
	import { storeToRefs } from 'pinia'

	import {
		getStorage,
		ref as storageRef,
		getDownloadURL
	} from 'firebase/storage'

	export default {
		name: 'OrderItem',
		props: {
			index: Number
		},

		setup(props) {
			const productsStore = useProductsStore()
			const { products } = storeToRefs(productsStore)

			const storage = getStorage()

			var imageSrcFull = ref()

			onMounted(async () => {
				imageSrcFull.value = await getDownloadURL(
					storageRef(storage, products.value[props.index].ImageSrc)
				)
			})

			// const imageSrcFull = firebase
			// 	.storage()
			// 	.ref(products.value[props.index].ImageSrc)
			// 	.getDownloadURL()

			return {
				imageSrcFull,
				productsStore
			}
		},

		methods: {},

		computed: {
			UnitsQty() {
				return (
					this.productsStore.products[this.index].Qty *
					this.productsStore.products[this.index].PackSize
				)
			}
		}
	}
</script>

<style></style>
