export const firebaseConfig = {
	apiKey: 'AIzaSyDwY4IjHtUweF-nqMNd6CyLX_RHSawNkdc',
	authDomain: 'ordertaker-3358a.firebaseapp.com',
	projectId: 'ordertaker-3358a',
	storageBucket: 'ordertaker-3358a.appspot.com',
	messagingSenderId: '37092587881',
	appId: '1:37092587881:web:3629497fefbbd70f9e25f8'
}

export const cloudFunctionEndPoint =
	'https://us-central1-ordertaker-3358a.cloudfunctions.net'
