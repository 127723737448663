import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
	state: () => {
		return {
			showOrderSuccess: false,
			showSpinner: false,
			showOrderError: false,
			orderErrorReason: null,
			selectedCustomer: null,
			QBOrder: {}
		}
	},
	actions: {
		ToggleOrderSuccess() {
			this.showOrderSuccess = this.showOrderSuccess ? false : true
		},

		OrderError(reason) {
			this.showOrderError = true
			this.orderErrorReason = reason
		},

		Spinner_On() {
			this.showSpinner = true
		},

		Spinner_Off() {
			this.showSpinner = false
		},

		handlePhoneNumber(phoneNumberUnformatted) {
			let phoneNumber = phoneNumberUnformatted
				.toString()
				.replace(/[^\d.+]/g, '')

			if (phoneNumber[0] == '+') {
				return phoneNumber
			}
			if (phoneNumber[0] == '0') {
				return '+61' + phoneNumber.substring(1)
			}
			return '+61' + phoneNumber
		}
	},
	getters: {}
})
