import { getProducts_firebase } from '../db/firebase'
import { defineStore } from 'pinia'

export const useProductsStore = defineStore('products', {
	state: () => {
		return {
			products: []
		}
	},
	actions: {
		async getProducts() {
			this.products = await getProducts_firebase()
			this.products.forEach((product) => (product.Qty = 0))
			this.products.forEach((product) => (product.Show = true))
		},
		IncreaseQty(id) {
			const index = this.products.findIndex(
				(product) => product.ProductId === id
			)

			const product = this.products.find(
				(product) => product.ProductId === id
			)

			if (!product.Qty) product.Qty = 0

			product.Qty++

			// console.log(JSON.stringify(product))
			this.products.splice(index, 1, product)
		},
		DecreaseQty(id) {
			const index = this.products.findIndex(
				(product) => product.ProductId === id
			)
			const product = this.products.find(
				(product) => product.ProductId === id
			)

			product.Qty = Math.max(0, product.Qty - 1)

			// console.log(JSON.stringify(product))
			this.products.splice(index, 1, product)
		},

		resetOrderForm() {
			this.products.forEach((product) => (product.Qty = 0))
			this.products.forEach((product) => (product.Description = ''))
		}
	},

	getters: {
		TotalQty: (state) => {
			const sum = state.products.reduce(
				(sum, { Qty }) => parseInt(sum) + (parseInt(Qty) || 0),
				0
			)

			return sum
		},
		TotalAmt: (state) => {
			const sum = state.products.reduce(
				(sum, { Qty, Price }) => sum + (Qty * Price || 0),
				0
			)

			return sum.toFixed(2)
		},
		getProductById: (state) => {
			return (ProductId) =>
				state.products.find(
					(product) => product.ProductId === ProductId
				)
		},

		getQtyByProductId: (state) => {
			return (ProductId) =>
				state.products.find(
					(product) => product.ProductId === ProductId
				).Qty
		},

		onOrder: (state) => {
			const OrderedProducts = state.products.filter(({ Qty }) => Qty > 0)

			const Order = []

			OrderedProducts.forEach((product) => {
				Order.push({
					ProductId: product.ProductId,
					QuickbooksID: product.QuickbooksID,
					ProductName: product.Name,
					Qty: product.Qty,
					Price: product.Price,
					Description: product.Description || '',
					TaxCodeRef: 14
				})
			})

			return Order
		}
	}
})
