import { initializeApp } from 'firebase/app'
import { getFirestore, limit, orderBy } from 'firebase/firestore'
import {
	collection,
	collectionGroup,
	doc,
	setDoc,
	getDocs,
	getDoc,
	updateDoc,
	query,
	where,
	deleteDoc
} from 'firebase/firestore'

import { firebaseConfig } from './config.js'

// initilize firebase db
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

// ******************  Firebase Products  *******************

const productsCollection = collection(db, 'products')

export const getProducts_firebase = async () => {
	const products = []

	try {
		const querySnapshot = await getDocs(productsCollection)
		querySnapshot.forEach((doc) => {
			products.push({
				ProductId: doc.data().ProductId,
				QuickbooksID: doc.data().QuickbooksID,
				Name: doc.data().Name,
				Price: doc.data().Price,
				PackSize: doc.data().PackSize,
				ProductRank: doc.data().ProductRank,
				Category: doc.data().Category,
				ImageSrc: doc.data().ImageSrc
			})

			products.sort(function (a, b) {
				return b.ProductRank - a.ProductRank
			})
		})

		return products
	} catch (e) {
		console.error('Error getting documents: ', e)
	}
}

export const addProduct_firebase = async (product) => {
	try {
		// const docRef = await addDoc(productsCollection, product);

		//console.log(product);

		await setDoc(doc(db, 'products', product.ProductId), product)

		console.log('Document written with ID: ', product.ProductId)
	} catch (e) {
		console.error('Error adding document', e)
	}
}

export const updateProduct_firebase = async (product) => {
	try {
		// If any keys are undefined then make them an empty array so there is no error in Firebase updateDoc
		// Object.entries(Product).forEach(([key, value]) => {if(!value) Product[key] = ''})

		for (const [key, value] of Object.entries(product)) {
			if (!value) product[key] = ''
		}

		await updateDoc(doc(db, `products`, product.ProductId), product)

		console.log('Customer updated to Firebase with ID: ', product.ProductId)
	} catch (e) {
		console.error('Error updating product in Firebase: ', e)
		throw e
	}
}

export const deleteProduct_firebase = async (product) => {
	try {
		await deleteDoc(doc(db, `products`, product.ProductId), product)

		console.log('Product deleted with ID: ', product.ProductId)
	} catch (e) {
		console.error('Error deleting product Firebase: ', e)
		throw e
	}
}

/* addProduct() {
// 	addProduct_firebase({
// 		ProductId: 'Paleta_Chocolate',
// 		ProductRank: '100',
// 		Name: 'Chocolate (Vegan)',
// 		Price: '50',
// 		PackSize: '25',
// 		ImageSrc: 'chocolate-layout-p-800.jpeg'
// 	})
// },
*/

// ******************  Firebase Customers  *******************

const customersCollection = collection(db, 'customers')

export const getCustomers_firebase = async () => {
	const customers = []

	try {
		const querySnapshot = await getDocs(customersCollection)
		querySnapshot.forEach((doc) => {
			customers.push({
				QB_Id: doc.data().QB_Id,
				CompanyName: doc.data().CompanyName,
				ManagerName: doc.data().ManagerName,
				MobileNumber: doc.data().MobileNumber,
				EmailAddress: doc.data().EmailAddress,
				Hide: doc.data()?.Hide
			})

			customers.sort(function (a, b) {
				return a.QB_Id - b.QB_Id
			})
		})

		return customers.reduce(function (result, customer) {
			// Filter customers to only those not hidden

			if (!customer.Hide) {
				result.push(customer)
			}
			return result
		}, [])
	} catch (e) {
		console.error('Error getting documents: ', e)
	}
}

export const getCustomer_firebase = async (QB_Id) => {
	try {
		const docRef = doc(db, 'customers', QB_Id.toString())
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			return docSnap.data()
		}
	} catch (e) {
		console.error('Error getting documents: ', e)
	}
}

export const getUserByMobileNumber_firebase = async (MobileNumber, uid) => {
	try {
		const q = query(
			customersCollection,
			where('MobileNumber', '==', MobileNumber),
			orderBy('QB_Id', 'desc'),
			limit(1)
		)
		const querySnapshot = await getDocs(q)

		const customer = []

		querySnapshot.forEach((doc) => {
			customer.push(doc.data())
		})

		if (!customer[0]) {
			const docRef = doc(db, 'users', uid)
			const docSnap = await getDoc(docRef)
			customer[0] = docSnap.data()
		}

		return customer[0]
	} catch (e) {
		console.log('User not an existing Customer: ', e)

		return null
	}
}

export const createCustomer_firebase = async (customer) => {
	try {
		await setDoc(doc(db, 'customers', customer.QB_Id), customer)

		console.log('Customer added to Firebase with ID: ', customer.QB_Id)
	} catch (e) {
		console.error('Error adding customers to Firebase: ', e)
		throw new Error(e)
	}
}

export const updateCustomer_firebase = async (customer) => {
	try {
		// If any keys are undefined then make them an empty array so there is no error in Firebase updateDoc
		// Object.entries(customer).forEach(([key, value]) => {if(!value) customer[key] = ''})

		for (const [key, value] of Object.entries(customer)) {
			if (!value) customer[key] = ''
		}

		await updateDoc(doc(db, `customers`, customer.QB_Id), customer)

		console.log('Customer updated to Firebase with ID: ', customer.QB_Id)
	} catch (e) {
		console.error('Error updating customers to Firebase: ', e)
		throw e
	}
}

export const deleteCustomer_firebase = async (customer) => {
	try {
		// If any keys are undefined then make them an empty array so there is no error in Firebase updateDoc
		// Object.entries(customer).forEach(([key, value]) => {if(!value) customer[key] = ''})

		for (const [key, value] of Object.entries(customer)) {
			if (!value) customer[key] = ''
		}

		customer.CompanyName = ''
		customer.ManagerName = ''
		customer.MobileNumber = ''
		customer.EmailAddress = ''
		customer.Hide = true

		await updateDoc(doc(db, `customers`, customer.QB_Id), customer)

		console.log('Customer hidden with ID: ', customer.QB_Id)
	} catch (e) {
		console.error('Error hidding customers Firebase: ', e)
		throw e
	}
}

// ******************  Firebase Orders  *******************

export const addOrder_firebase = async (uid, QBOrder, order) => {
	try {
		// console.log(`Order ${JSON.stringify(order)}`)

		await setDoc(
			doc(db, 'orders', uid, 'customerOrders', order.orderDateTime),
			{
				customerName: QBOrder.Invoice.CustomerRef.name,
				customerID: QBOrder.Invoice.CustomerRef.value,
				orderTotalQty: order.orderTotalQty,
				orderTotalAmt: QBOrder.Invoice.TotalAmt,
				orderDocNumber: QBOrder.Invoice.DocNumber,
				orderDateTime: order.orderDateTime,
				orderItems: order.orderItems
			}
		)

		console.log('Order added to Firebase with ID: ', order.orderDateTime)
	} catch (error) {
		return new Error(error)
	}
}

export const getOrders_firebase = async (uid, admin) => {
	if (admin) {
		return getAllOrders_firebase()
	}
	const ordersCollection = collection(db, `orders/${uid}/customerOrders`)
	const orders = []

	try {
		const q = query(ordersCollection, orderBy('orderDateTime', 'desc'))

		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc) => {
			orders.push({
				customerID: doc.data().customerID,
				customerName: doc.data().customerName,
				orderDateTime: doc.data().orderDateTime,
				orderTotalQty: doc.data().orderTotalQty,
				orderTotalAmt: doc.data().orderTotalAmt,
				orderItems: doc.data().orderItems
			})
		})

		return orders
	} catch (e) {
		console.error('Error getting documents: ', e)
	}
}

export const getAllOrders_firebase = async () => {
	const ordersCollectionGroup = collectionGroup(db, 'customerOrders')
	const orders = []
	try {
		const q = query(ordersCollectionGroup, orderBy('orderDateTime', 'desc'))

		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc) => {
			orders.push({
				customerID: doc.data().customerID,
				customerName: doc.data().customerName,
				orderDateTime: doc.data().orderDateTime,
				orderTotalQty: doc.data().orderTotalQty,
				orderTotalAmt: doc.data().orderTotalAmt,
				orderItems: doc.data().orderItems
			})
		})

		return orders
	} catch (e) {
		console.error('Error getting documents: ', e)
	}
}

export const getOrder_firebase = async (uid, orderId) => {
	const ordersCollection = collection(db, `orders/${uid}/customerOrders`)
	const Order = await ordersCollection.doc(orderId).get()
	return Order.exist ? Order.data() : null
}

export const updateOrder_firebase = (uid, orderId, Order) => {
	const ordersCollection = collection(db, `orders/${uid}/customerOrders`)
	return ordersCollection.doc(orderId).update(Order)
}
;``
export const deleteOrder_firebase = (uid, orderId) => {
	const ordersCollection = collection(db, `orders/${uid}/customerOrders`)
	return ordersCollection.doc(orderId).delete()
}
