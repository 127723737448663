<template>
	<ModalSpinner />
	<div class="SigninView">
		<div class="flex flex-col items-center">
			<div class="w-11/12 bg-white p-6 max-w-sm shadow-2xl rounded-lg">
				<div class="min-w-full">
					<div class="flex justify-center">
						<img
							src="/img/paleta_logo-01.png"
							class="h-10"
							alt="Paleta"
						/>
					</div>

					<div
						class="flex justify-center space-y-6 my-8 text-gray-700"
					>
						<h1
							v-text="
								registerMode
									? 'Register'
									: 'Sign in with your number'
							"
							class="text-center text-xl font-normal"
						></h1>
					</div>

					<form class="mt-8 space-y-6" action="#">
						<div
							v-for="(formError, index) in formErrors"
							:key="index"
						>
							<label class="text-sm text-red-700 mb-2">
								{{ formError }}
							</label>
						</div>
						<div class="grid grid-cols-2 gap-4">
							<div
								:class="registerMode ? '' : 'hidden'"
								class="mb-4"
							>
								<label
									for="GivenName"
									class="hidden text-sm text-gray-700 mb-4"
									>First Name</label
								>
								<input
									v-model="GivenName"
									ref="GivenNameInput"
									name="GivenName"
									type="text"
									autocomplete="first-name"
									required="true"
									class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
									placeholder="First Name"
								/>
							</div>
							<div
								:class="registerMode ? '' : 'hidden'"
								class="mb-4"
							>
								<label
									for="FamilyName"
									class="hidden text-sm text-gray-700 mb-4"
									>Last Name</label
								>
								<input
									v-model="FamilyName"
									name="FamilyName"
									type="text"
									autocomplete="last-name"
									required=""
									class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
									placeholder="Last Name"
								/>
							</div>
						</div>

						<div :class="registerMode ? '' : 'hidden'" class="mb-4">
							<label
								for="companyName"
								class="hidden text-sm text-gray-700 mb-4"
								>Company Name</label
							>
							<input
								v-model="CompanyName"
								type="text"
								autocomplete="company-name"
								required=""
								class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								placeholder="Company Name"
							/>
						</div>
						<div class="mb-4">
							<label
								for="phonenumber"
								class="hidden text-sm text-gray-700 mb-4"
								>Mobile Number</label
							>
							<input
								v-model="phoneNumber"
								autofocus
								ref="MobileNumberInput"
								id="phone-number"
								name="phonenumber"
								type="tel"
								autocomplete="tel"
								required
								class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								placeholder="Phone Number"
							/>
						</div>
						<div :class="registerMode ? '' : 'hidden'" class="mb-4">
							<label
								for="EmailAddress"
								class="hidden text-sm text-gray-700 mb-4"
								>Email</label
							>
							<input
								v-model="EmailAddress"
								type="text"
								autocomplete="email"
								required=""
								class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								placeholder="Email"
							/>
						</div>

						<div
							v-show="!waitingforSMSCode"
							class="flex items-center justify-between"
						>
							<!-- <div class="flex items-center">
								<input
									v-model="rememberMe"
									name="remember-me"
									type="checkbox"
									class="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-blue-600"
								/>
								<label
									for="remember-me"
									class="ml-2 block text-sm text-gray-700"
								>
									Remember me
								</label>
							</div> -->

							<div class="text-sm">
								<a
									v-text="registerMode ? 'Login' : 'Register'"
									@click="ToggleRegister"
									class="font-medium text-blue-500 hover:text-blue-600 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-blue-500"
									href="#"
								></a>
							</div>
						</div>

						<div id="recaptcha" ref="recaptchaElement"></div>

						<div v-show="!registerMode">
							<button
								@click.stop="requestOTP"
								id="sign-in-button"
								class="w-full py-2 px-4 border border-transparent text-sm font-medium bg-blue-500 rounded-md focus:outline-none text-white hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
							>
								{{ signInButton }}
							</button>
						</div>
						<div v-show="registerMode">
							<button
								@click.stop="requestOTP"
								class="w-full py-2 px-4 border border-transparent text-sm font-medium bg-blue-500 rounded-md focus:outline-none text-white hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
							>
								Register
							</button>
						</div>

						<div v-if="waitingforSMSCode">
							<div class="space-y-6 my-4 text-gray-700">
								<h1 class="text-left text-md font-bold">
									SMS Verification Code
								</h1>
							</div>
							<div class="flex flex-row">
								<v-otp-input
									ref="otpInput"
									input-classes="appearance-none relative block w-full p-2 w-10 h-10  mx-1 border border-gray-300 text-center  text-lg text-gray-900 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
									inputmode="numeric"
									separator=""
									:num-inputs="6"
									:should-auto-focus="false"
									:is-input-num="true"
									:placeholder="['', '', '', '', '', '']"
									@on-complete="handleOnComplete"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// /* global grecaptcha */
	import { ref } from 'vue'
	import { useRouter } from 'vue-router'
	import { useMainStore } from '../stores/main'
	import { useAuthStore } from '../stores/auth'
	import ModalSpinner from '@/components/ModalSpinner.vue'
	import VOtpInput from 'vue3-otp-input'
	import {
		getAuth,
		RecaptchaVerifier,
		signInWithPhoneNumber
	} from 'firebase/auth'
	import { createCustomer_firebase } from '../db/firebase'
	import { createcustomer_Quickbooks } from '../db/quickbooks'

	import { useToast } from 'vue-toast-notification'
	import 'vue-toast-notification/dist/theme-sugar.css'

	export default {
		name: 'LoginView',
		props: {},

		components: { ModalSpinner, VOtpInput },

		setup() {
			const mainStore = useMainStore()
			const authStore = useAuthStore()
			const router = useRouter()
			//Form Inputs
			const rememberMe = ref(true)
			const GivenNameInput = ref()
			const MobileNumberInput = ref()
			//Setup modes
			const registerMode = ref()
			const waitingforSMSCode = ref()
			const SMSverified = ref()
			//set up OTP input component
			const smsVerificationCode = ref()
			const otpInput = ref(null)

			const handleOnComplete = (code) => {
				otpInput.value.clearInput()
				//otpInput.value.focusInput(0)
				waitingforSMSCode.value = false
				mainStore.Spinner_On()
				verifyCode(code)
			}

			const $toast = useToast()

			const signInButton = ref('Send SMS Verification')

			// Form Values
			const CompanyName = ref()
			const EmailAddress = ref()
			const GivenName = ref()
			const FamilyName = ref()
			const phoneNumber = ref()
			const formErrors = ref()

			//Login
			async function requestOTP(event) {
				event.preventDefault()
				if (!checkFormInputs()) {
					return
				}

				//mainStore.Spinner_On()

				// If OTP already verified in register screen then register to Firestore
				if (registerMode.value && SMSverified.value) {
					formErrors.value = []
					register()
					return
				}

				const auth = getAuth()
				auth.useDeviceLanguage()

				if (!window.recaptchaVerifier) {
					window.recaptchaVerifier = new RecaptchaVerifier(
						'sign-in-button',
						{
							size: 'invisible'
						},
						auth
					)
				}

				// Create invisible recaptchaVerifier

				sendOTPCode()
			}

			async function sendOTPCode() {
				// Add Australia +61 prefix if no suffix else allow international numbers but they must have country suffix
				let formattedPhoneNumber = mainStore.handlePhoneNumber(
					phoneNumber.value
				)

				const auth = getAuth()
				auth.useDeviceLanguage()

				// // Send Phone Verification Code
				signInWithPhoneNumber(
					auth,
					formattedPhoneNumber,
					window.recaptchaVerifier
				)
					.then((confirmationResult) => {
						// SMS sent. Prompt user to type the code from the message, then sign the
						// user in with confirmationResult.confirm(code).
						window.confirmationResult = confirmationResult

						mainStore.Spinner_Off()

						console.log(`signInWithPhoneNumber: SMS sent`)
						waitingforSMSCode.value = true
						signInButton.value = 'Resend Code'

						//window.recaptchaVerifier.clear()
					})
					.catch((error) => {
						// Error; SMS not sent
						formErrors.value = []

						mainStore.Spinner_Off()

						if (
							error
								.toString()
								.includes('auth/invalid-phone-number')
						) {
							formErrors.value.push('Invalid Phone Number')
						} else if (
							error
								.toString()
								.includes(
									'reCAPTCHA client element has been removed'
								)
						) {
							formErrors.value.push('Recaptcha error')
							console.error(error)
						} else if (
							error
								.toString()
								.includes('auth/auth/internal-error')
						) {
							location.reload()
						} else {
							formErrors.value.push('Please refresh page')
							console.error(error)
						}
					})
					.finally(() => {
						// reset the reCAPTCHA so the user can try again
						// window.recaptchaVerifier.render().then((widgetId) => {
						// 	window.recaptchaWidgetId = widgetId
						// 	grecaptcha.reset(widgetId)
						// })
						//window.recaptchaVerifier.clear()
					})
			}

			// Verify OTP code entered
			async function verifyCode(code) {
				otpInput.value.clearInput()
				try {
					// confirm code
					const result = await window.confirmationResult.confirm(code)

					// If firebase Auth returns to no user then stop
					if (!result.user) {
						formErrors.value.push('Invalid user, please try again')
						console.error('Error verifying code')
						return
					}

					// Auth Code and User verified
					waitingforSMSCode.value = false
					SMSverified.value = true
					window.recaptchaVerifier.clear()

					// If register mode is on then register in Firestore
					if (registerMode.value) {
						formErrors.value = []
						register()
						return
					}

					// check if user is already registered in Firestore in the Customers collection
					const userDetails =
						await authStore.setUserDetailsByMobileNumber(
							getAuth().currentUser?.phoneNumber
						)

					// check if user is registered in Firestore in the Users collection
					// ToDo

					// If user is in Firestore then login
					if (userDetails) {
						authStore.login(rememberMe.value)

						// got to homepage
						router.push('/')

						return
					}

					// if user is not registered in Firestore then Toggle Register Mode
					registerMode.value = true
					signInButton.value = 'Send SMS Verification'
					formErrors.value = []
				} catch (e) {
					formErrors.value = []
					waitingforSMSCode.value = true
					formErrors.value.push('Invalid Code, please try again')

					console.log(`Error verifying code and logging in ${e}`)
				} finally {
					mainStore.Spinner_Off()
				}
			}

			// Register
			async function register() {
				mainStore.Spinner_On()

				// Form validation
				if (!checkFormInputs()) {
					mainStore.Spinner_Off()
					return
				}

				// Define customer object
				const customer = {
					CompanyName: CompanyName.value.trim(),
					EmailAddress: EmailAddress.value.trim(),
					GivenName: GivenName.value.trim(),
					FamilyName: FamilyName.value.trim(),
					ManagerName:
						GivenName.value.trim() + ' ' + FamilyName.value.trim(),
					MobileNumber: mainStore.handlePhoneNumber(phoneNumber.value)
				}

				// Register to Quickbooks
				let QBCustomer = {}
				try {
					console.log('Registering in QB')
					QBCustomer = await createcustomer_Quickbooks(customer)

					customer.QB_Id = QBCustomer.Id
				} catch (e) {
					console.log(`Unable to create QB customer${e}`)
					mainStore.Spinner_Off()

					$toast.open({
						type: 'error',
						message:
							'Customer was unable to be registered, please contact support@paleta.com.au',
						position: 'top'
					})

					return
				}
				if (!('Id' in QBCustomer)) {
					mainStore.Spinner_Off()
					$toast.open({
						type: 'error',
						message:
							'Customer missing ID, please contact support@paleta.com.au',
						position: 'top'
					})
					return
				}

				console.log(
					`QB Customer created: ${JSON.stringify(QBCustomer)}`
				)

				// Register to Firebase
				try {
					console.log('Registering in FB')
					await createCustomer_firebase(customer)
				} catch (e) {
					console.log(`Unable to create Firebase customer${e}`)

					$toast.open({
						type: 'error',
						message:
							'Customer was unable to be saved, please contact support@paleta.com.au',
						position: 'top'
					})
					return
				} finally {
					mainStore.Spinner_Off()
				}

				// Login

				// setUserDetailsByMobileNumber Firestore in the Customers collection
				await authStore.loadUser()

				authStore.login(true)
				router.push('/')
			}

			// Toggle Register
			function ToggleRegister() {
				if (!registerMode.value) {
					registerMode.value = true
					setTimeout(() => {
						GivenNameInput.value.focus()
					}, 5)
					return
				}
				router.go()

				// registerMode.value = false
				// setTimeout(() => {
				// 	MobileNumberInput.value.focus()
				// }, 5)
			}

			function checkFormInputs() {
				formErrors.value = []
				if (
					!phoneNumber.value ||
					phoneNumber.value.length < 9 ||
					!parseInt(phoneNumber.value)
				) {
					formErrors.value.push('Enter your Mobile Number')
				}

				if (!registerMode.value)
					return formErrors.value.length ? false : true

				if (!GivenName.value) {
					formErrors.value.push('Enter your First Name')
				}
				if (!FamilyName.value) {
					formErrors.value.push('Enter your Last Name')
				}

				if (!CompanyName.value) {
					formErrors.value.push('Enter your Company Name')
				}
				if (!EmailAddress.value) {
					formErrors.value.push('Enter your Email Address')
				} else if (
					!EmailAddress.value
						.toLowerCase()
						.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/)
				) {
					formErrors.value.push('Email Address is not valid')
				}

				// formErrors.forEach((er) => console.log(er))

				return formErrors.value.length ? false : true
			}

			return {
				handleOnComplete,
				ToggleRegister,
				register,
				MobileNumberInput,
				GivenNameInput,
				requestOTP,
				smsVerificationCode,
				otpInput,
				registerMode,
				waitingforSMSCode,
				rememberMe,
				CompanyName,
				EmailAddress,
				GivenName,
				FamilyName,
				phoneNumber,
				formErrors,
				signInButton
			}
		}
	}
</script>
