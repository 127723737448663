import { createRouter, createWebHistory } from 'vue-router'

import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { useAuthStore } from '@/stores/auth'

import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'

import OrderHistoryView from '@/views/OrderHistoryView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	{
		path: '/management',
		name: 'management',
		component: () => import('@/views/ManagementView.vue')
	},
	{
		path: '/order-history',
		name: 'order-history',
		component: OrderHistoryView
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

const getCurrentUser = async () => {
	function getUser() {
		return new Promise((resolve, reject) => {
			const unsubscribe = onAuthStateChanged(
				getAuth(),
				(user) => {
					unsubscribe()
					resolve(user)
				},
				reject
			)
		})
	}

	const currentUser = await getUser()

	const authStore = useAuthStore()

	var customerDetails = authStore.customerDetails

	if (!customerDetails) {
		customerDetails = await authStore.loadUser()
	}

	return !!currentUser && !!authStore.user && !!customerDetails
}

router.beforeEach(async (to) => {
	// redirect to login page if not logged in and trying to access a restricted page

	const privatePages = ['/', '/management', '/order-history']

	// const authRequired = !publicPages.includes(to.path);
	const authRequired = privatePages.includes(to.path)

	const isLoggedIn = await getCurrentUser()

	if (isLoggedIn) {
		if (authRequired) {
			return null
		}

		return { name: 'home' }
	} else if (authRequired) {
		return { name: 'login' }
	} else {
		return null
	}
})

export default router
