<template>
	<div class="w-80 my-4 ml-6 md:ml-16 justify-center">
		<multiselect
			v-model="selected"
			select-label="Select"
			:options="customers"
			:custom-label="CompanyAndManagerNames"
			:close-on-select="true"
			:clear-on-select="false"
			placeholder="Pick a Customer"
			label="CompanyName"
			track-by="QB_Id"
			@select="CustomerSelected"
		>
		</multiselect>

		<div
			class="border my-2 p-2 min-h-[80px] rounded-xl border-gray-200 focus:outline-none focus:border-orange-300"
		>
			<div class="mt-2 font-medium">
				{{ selected?.QB_Id }}
			</div>
			<div class="mt-2 font-medium">
				{{ selected?.CompanyName }}
			</div>
			<div class="mt-2 font-medium">
				{{ selected?.ManagerName }}
			</div>
			<div class="mt-2 font-medium">
				{{ selected?.MobileNumber }}
			</div>
			<div class="mt-2 font-medium">
				{{ selected?.EmailAddress }}
			</div>
		</div>
	</div>
</template>

<script>
	import Multiselect from 'vue-multiselect'
	import { ref } from 'vue'
	import { useMainStore } from '../stores/main'

	export default {
		name: 'CustomerSelector',
		props: ['customers'],
		components: {
			Multiselect
		},
		setup() {
			const selected = ref('')

			function CompanyAndManagerNames({ CompanyName }) {
				var shortName =
					CompanyName.slice(0, 30) +
					(CompanyName.length > 30 ? '...' : '')
				return `${shortName}`
			}

			const mainStore = useMainStore()

			function CustomerSelected(SelectedCustomer) {
				mainStore.selectedCustomer = SelectedCustomer
			}

			return {
				CompanyAndManagerNames,
				CustomerSelected,
				selected
			}
		}
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
