import { defineStore } from 'pinia'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import {
	getCustomer_firebase,
	getUserByMobileNumber_firebase
} from '../db/firebase'

export const useAuthStore = defineStore('auth', {
	state: () => {
		return {
			user: getAuth().currentUser,
			customerDetails: null
		}
	},
	actions: {
		async getCustomer(QB_Id) {
			this.customerDetails = await getCustomer_firebase(QB_Id)

			return this.customerDetails
		},

		async setUserDetailsByMobileNumber(MobileNumber) {
			const auth = getAuth()

			// update pinia state
			this.user = auth.currentUser

			return (this.customerDetails = await getUserByMobileNumber_firebase(
				MobileNumber,
				this.user.uid
			))
		},

		handleAuthStateChanged() {
			let auth = getAuth()
			onAuthStateChanged(auth, (user) => {
				if (user) {
					if (!this.customerDetails) {
						this.loadUser()
					}
				} else {
					this.customerDetails = null
				}
			})
		},

		async login(rememberMe) {
			const auth = getAuth()
			// Query customer details from Firestore of authenticated user
			try {
				// console.log(`userDetails ${JSON.stringify(userDetails)}`)

				if (!this.customerDetails) {
					await console.log(
						`No User Found ${JSON.stringify(this.customerDetails)}`
					)
					return
				}

				// Lookup the user associated with the specified uid.

				// auth.currentUser
				// 	.getIdTokenResult()
				// 	.then((idTokenResult) => {
				// 		// // Confirm the user is an Admin.
				// 		if (idTokenResult.claims.admin) {
				// 			//console.log('admin:' + idTokenResult.claims.admin)
				// 			//console.log(idTokenResult.claims)
				// 		} else {
				// 			// console.log('admin: false')
				// 		}
				// 	})
				// 	.catch((error) => {
				// 		console.log(error)
				// 	})

				// getAuth()
				// 	.getUser(this.user.uid)
				// 	.then((userRecord) => {
				// 		// The claims can be accessed on the user record.
				// 		console.log('admin:' + userRecord.customClaims['admin'])
				// 		console.log(
				// 			'customerId:' +
				// 				userRecord.customClaims['customerId']
				// 		)
				// 		console.log(userRecord.customClaims)
				// 	})
			} catch (e) {
				console.log('Error getting user Details:' + e)
				return
			}

			// console
			// 	.log
			// 	// `login currentUser: ${JSON.stringify(auth.currentUser)}`

			this.saveCookie(rememberMe, auth.currentUser)

			//this.user = JSON.parse(localStorage.getItem('user'))
		},

		async loadUser() {
			// update pinia state
			this.user = getAuth().currentUser

			if (this.user) {
				return this.setUserDetailsByMobileNumber(
					getAuth().currentUser?.phoneNumber
				)
			}
		},

		saveCookie(rememberMe, user) {
			//console.log('trying to save cookie')
			if (rememberMe === true) {
				var date = new Date()
				date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * 90) //90 days days
				//splicing cookies
				window.document.cookie =
					'uid' +
					'=' +
					user.uid +
					';path=/;expires=' +
					date.toGMTString()
				window.document.cookie =
					'stsTokenManager' +
					'=' +
					JSON.stringify(user.stsTokenManager) +
					';path=/;expires=' +
					date.toGMTString()
				window.document.cookie =
					'claims' +
					'=' +
					user.claims +
					';path=/;expires=' +
					date.toGMTString()
				//console.log('cookie saved')
			}
		},

		async logout() {
			console.log('logging out')
			let auth = getAuth()
			await signOut(auth).then(() => {
				this.user = null
				this.customerDetails = null
				this.isLoggedIn = false
			})
			window.location.reload()
		}
	},

	getters: {
		getLogginStatus: (state) => {
			return !!state.customerDetails
		},

		getcustomerDetails: (state) => {
			return state.customerDetails
		},

		isAdmin: (state) => {
			return (
				JSON.parse(
					state.user?.auth?.currentUser?.reloadUserInfo
						?.customAttributes || null
				)?.admin || false
			)
		}
	}
})
