<script setup>
	import { useMainStore } from '../stores/main'

	const mainStore = useMainStore()
</script>

<template>
	<div class="relative z-10" v-if="mainStore.showSpinner">
		<div
			as="template"
			enter="ease-out duration-300"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="ease-in duration-200"
			leave-from="opacity-100"
			leave-to="opacity-0"
		>
			<div
				class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			></div>
		</div>

		<div class="fixed z-10 inset-0 overflow-y-auto">
			<div class="flex items-center justify-center h-4/6">
				<div
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="relative rounded-2xl p-7 transform transition-all sm:max-w-lg sm:w-full"
					>
						<font-awesome-icon
							:icon="['fas', 'sync']"
							spin
							class="text-6xl"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
