import { cloudFunctionEndPoint } from './config.js'

/**
 * Connect Company to Quickbooks
 */

export const connect_Quickbooks = async () => {
	try {
		const authUri = await fetch(
			`${cloudFunctionEndPoint}/QuickbooksIntegration/connectQuickbooks`,
			{
				method: 'get'
			}
		).then((response) => response.text())

		console.log(`The authUri is: ${authUri}`)

		// Launch Popup using the JS window Object
		var parameters = 'location=1,width=800,height=650'
		parameters +=
			',left=' +
			(screen.width - 800) / 2 +
			',top=' +
			(screen.height - 650) / 2
		var win = window.open(authUri, 'connectPopup', parameters)
		var pollOAuth = window.setInterval(function () {
			try {
				if (win.document.URL.indexOf('code') != -1) {
					window.clearInterval(pollOAuth)
					win.close()
					location.reload()
				}
				// window.clearInterval(pollOAuth)
				// win.close()
				// location.reload()
			} catch (e) {
				console.log(e)
			}
		}, 100)
	} catch (e) {
		console.error('Error Connecting Company to Quickbooks: ', e)
	}
}

/**
 * create new customer from Quickbooks
 */

export const createcustomer_Quickbooks = async (customer) => {
	return await fetch(
		`${cloudFunctionEndPoint}/QuickbooksIntegration/createQuickbooksCustomer`,
		{
			method: 'post',
			body: JSON.stringify(customer)
		}
	)
		.then((response) => response.json())
		.then((data) => {
			// if (!response.ok) {
			// 	throw new Error('Could not create Customer')
			// }
			return data
		})

		.catch((error) => {
			throw error
		})
}

/**
 * get customers from Quickbooks
 */

export const getCustomers_Quickbooks = async () => {
	try {
		const response = await fetch(
			`${cloudFunctionEndPoint}/QuickbooksIntegration/getQuickbooksCustomers`,
			{
				method: 'get'
			}
		)
		console.log(`getcustomers_Quickbooks: ${JSON.stringify(response)}`)

		window.reload()
	} catch (e) {
		console.error('getcustomers_Quickbooks Error: ', e)
		// alert('Could not get Customers form Quickbooks')
	}
}

/**
 * get products from Quickbooks
 */

export const getProducts_Quickbooks = async () => {
	try {
		const response = await fetch(
			`${cloudFunctionEndPoint}/QuickbooksIntegration/getQuickbooksProductInfo`,
			{
				method: 'get'
			}
		)
		console.log(`getproducts_Quickbooks: ${JSON.stringify(response)}`)
	} catch (e) {
		console.error('getproducts_Quickbooks Error: ', e)
		// alert('Could not get Products form Quickbooks')
	}
}

/**
 * create new invoice in Quickbooks
 */

export const addOrder_Quickbooks = async (order) => {
	return await fetch(
		`${cloudFunctionEndPoint}/QuickbooksIntegration/createInvoice`,
		{
			method: 'post',
			body: JSON.stringify(order)
		}
	)
		.then((response) => response.json())
		.then((data) => {
			console.log(`data: ${data}`)

			return data
		})

		.catch((error) => {
			console.error('Could not create order')
			throw error
		})
}
