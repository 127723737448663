<script setup>
	import { ref, watch, onMounted } from 'vue'
	import dayjs from 'dayjs'

	import { storeToRefs } from 'pinia'

	// import { useMainStore } from '@/stores/main'
	// const MainStore = useMainStore()

	import { getOrders_firebase } from '../db/firebase'
	import { useAuthStore } from '../stores/auth'
	const authStore = useAuthStore()

	const { isAdmin } = storeToRefs(authStore)

	const showOrders = ref()

	const orders = ref()

	const showOrderDetail = ref()
	const Orderindex = ref()

	function ToggleOrderDetail(orderID) {
		showOrderDetail.value = !showOrderDetail.value
		Orderindex.value = orderID
	}

	watch(isAdmin, async () => {
		const ordersRaw = await getOrders_firebase(
			authStore.user.uid,
			authStore.isAdmin
		)

		orders.value = ordersRaw.map((object) => ({
			...object,
			orderDateTimeFormatted: dayjs(object.orderDateTime).format(
				'D MMM YYYY, HH:mm'
			)
		}))
	})

	onMounted(async () => {
		const ordersRaw = await getOrders_firebase(
			authStore.user.uid,
			authStore.isAdmin
		)

		orders.value = ordersRaw.map((object) => ({
			...object,
			orderDateTimeFormatted: dayjs(object.orderDateTime).format(
				'D MMM YYYY, HH:mm'
			)
		}))
	})
</script>

<template>
	<div
		class="relative z-10"
		v-if="showOrderDetail"
		@click="ToggleOrderDetail"
	>
		<div>
			<div
				class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			></div>
		</div>

		<div class="fixed z-10 inset-0 overflow-y-auto">
			<div
				class="flex items-center justify-center min-h-full p-4 text-center sm:p-0"
			>
				<div>
					<table
						class="m-8 text-center bg-white text-gray-900 border-2 border-slate-200 shadow-md"
					>
						<thead class="text-gray-700 bg-gray-100">
							<tr>
								<th scope="col" class="py-3 px-6">Product</th>
								<th scope="col" class="py-3 px-6">Quantity</th>
								<th scope="col" class="py-3 px-6">Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(orderItem, index) in orders[Orderindex]
									.orderItems"
								:key="index"
								class="border-b hover:bg-gray-100 hover:text-gray-900"
							>
								<td class="py-4 px-6">
									{{ orderItem.ProductName }}
								</td>
								<td class="py-4 px-6">
									{{ orderItem.Qty || 0 }} bags
								</td>
								<td class="py-4 px-6">
									${{
										(
											Number(orderItem.Qty) *
											Number(orderItem.Price)
										).toFixed(2) || 0
									}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="orders">
		<div class="flex flex-col items-center">
			<div class="bg-white pl-2 w-11/12 shadow-xl rounded-lg mb-2">
				<div class="mx-auto max-w-md min-w-full">
					<div>
						<h2>
							<button
								@click="showOrders = !showOrders"
								type="button"
								class="flex justify-center w-full p-5 text-xl font-medium text-gray-600 focus:outline-none"
							>
								<span>Order History</span>
							</button>
						</h2>
						<div class="flex justify-center">
							<div
								class="mt-2 overflow-x-auto relative sm:rounded-lg"
							>
								<table
									class="mb-6 mx-2 text-center text-gray-900 border-2 border-slate-200 shadow-md rounded-lg"
								>
									<thead
										class="text-gray-700 bg-gray-100 hidden md:table-header-group"
									>
										<tr>
											<th scope="col" class="py-3 px-6">
												Order Date
											</th>
											<th scope="col" class="py-3 px-6">
												Customer Name
											</th>
											<th scope="col" class="py-3 px-6">
												Quantity
											</th>
											<th scope="col" class="py-3 px-6">
												Value
											</th>
											<th class="py-3 px-6">Details</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(order, orderID) in orders"
											:key="orderID"
											class="border-b hover:bg-gray-100 hover:text-gray-900 grid grid-cols-1 md:table-row"
										>
											<td class="py-4 px-3 text-left">
												<span
													class="md:hidden text-gray-900 font-medium mx-3"
													>Order Date:
												</span>
												{{
													order.orderDateTimeFormatted
												}}
											</td>
											<td class="py-4 px-3 text-left">
												<span
													class="md:hidden text-gray-900 font-medium mx-3"
													>Customer Name:
												</span>
												{{ order.customerName }}
											</td>
											<td
												class="py-4 px-3 text-gray-900 font-medium text-left"
											>
												<span
													class="md:hidden text-gray-900 font-medium mx-3"
													>Quantity:
												</span>
												<span
													class="text-gray-900 font-medium"
												>
													{{
														order.orderTotalQty || 0
													}}
													bags
												</span>
											</td>
											<td
												class="py-4 px-3 text-gray-900 font-medium text-left"
											>
												<span
													class="md:hidden text-gray-900 font-medium mx-3"
													>Value:
												</span>
												<span
													class="text-gray-900 font-medium"
													>${{
														order.orderTotalAmt || 0
													}}
												</span>
											</td>
											<td
												class="py-4 px-6 text-center md:text-right"
											>
												<a
													href="#"
													@click="
														ToggleOrderDetail(
															orderID
														)
													"
													class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
													>Details</a
												>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
