import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import './index.css'

import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
	faSync,
	faUser,
	faCheck,
	faMagnifyingGlass,
	faMicrophone,
	faMinus,
	faPlus,
	faWindowClose,
	faClose,
	faXmark,
	faSave,
	faPenToSquare,
	faChevronUp,
	faChevronDown
} from '@fortawesome/free-solid-svg-icons'

library.add(
	faSync,
	faUser,
	faCheck,
	faMagnifyingGlass,
	faMicrophone,
	faMinus,
	faPlus,
	faWindowClose,
	faClose,
	faXmark,
	faSave,
	faPenToSquare,
	faChevronUp,
	faChevronDown
)

createApp(App)
	.component('font-awesome-icon', FontAwesomeIcon)
	.use(createPinia())
	.use(router)
	.mount('#app')

//<font-awesome-icon:icon="['fas', 'xmark']"/>
