<script setup>
	import { reactive } from 'vue'

	import { RouterLink } from 'vue-router'
	import { useAuthStore } from '@/stores/auth'

	import { storeToRefs } from 'pinia'

	//import { getAuth } from 'firebase/auth'

	import NavDropdown from './NavDropdown.vue'

	const authStore = useAuthStore()

	const state = reactive({
		showMenu: false
	})

	const toggleNav = () => (state.showMenu = !state.showMenu)

	const showMenuOff = () => (state.showMenu = false)

	const { customerDetails, isAdmin } = storeToRefs(authStore)
</script>
<template>
	<div v-show="authStore.getLogginStatus">
		<nav class="mb-2 bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded">
			<div
				class="container flex flex-wrap justify-between items-center mx-auto"
			>
				<a href="/" class="flex items-center">
					<img
						src="/img/paleta_logo-01.png"
						class="mr-3 h-6 sm:h-9"
						alt="Logo"
					/>
					<span
						class="self-center text-xl font-semibold whitespace-nowrap"
					></span>
				</a>

				<div class="flex items-center md:order-2">
					<!-- Dropdown menu -->
					<NavDropdown placement="right">
						<!-- Button content -->
						<template v-slot:button>
							<div
								class="rounded-full mr-2 focus:ring-1 focus:ring-gray-600"
							>
								<i class="w-10 h-10 rounded-full text-gray-700">
									<font-awesome-icon
										:icon="['fas', 'user']"
										class="mt-2 w-4 h-4"
									/>
								</i>
							</div>

							<font-awesome-icon
								class="text-gray-700"
								:icon="['fas', 'chevron-down']"
							/>
						</template>

						<!-- Opened dropdown content -->
						<template v-slot:content>
							<div
								class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-orange-200 hover:text-gray-700"
							>
								{{ customerDetails?.ManagerName }}<br />

								{{ customerDetails?.CompanyName }} <br />

								{{ customerDetails?.MobileNumber }}

								{{ customerDetails?.EmailAddress }}
							</div>

							<hr />

							<a
								@click="authStore.logout()"
								href="#"
								class="flex w-full justify-between items-center rounded px-4 py-1 my-1 hover:bg-red-500 hover:text-white hover:font-bold"
								>Log out</a
							>
						</template>
					</NavDropdown>

					<button
						@click="toggleNav()"
						data-collapse-toggle="mobile-menu-2"
						type="button"
						class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
						aria-controls="mobile-menu-2"
						aria-expanded="false"
					>
						<span class="sr-only">Open main menu</span>
						<svg
							class="w-6 h-6"
							aria-hidden="true"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
								clip-rule="evenodd"
							></path>
						</svg>
					</button>
				</div>
				<div
					:class="state.showMenu ? '' : 'hidden'"
					class="justify-between items-center w-full md:flex md:w-auto md:order-1"
					id="mobile-menu-2"
				>
					<ul
						class="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white"
					>
						<router-link to="/" @click="showMenuOff()">
							<a
								href="#"
								class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
								>Home</a
							>
						</router-link>

						<router-link to="/order-history" @click="showMenuOff()">
							<a
								href="#"
								class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
								>Order History</a
							>
						</router-link>

						<router-link
							to="/management"
							@click="showMenuOff()"
							v-if="isAdmin"
						>
							<a
								href="#"
								class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
								>Management</a
							>
						</router-link>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>
