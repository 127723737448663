<template>
	<ModalSpinner />

	<div class="flex flex-col items-center">
		<div
			class="bg-white pt-4 pb-28 md:pb-40 md:pt-8 shadow-2xl max-w-lg sm:rounded-lg px-3 md:px-5"
		>
			<div class="mx-auto max-w-md min-w-full mb-4">
				<div class="flex justify-center">
					<img
						src="/img/paleta_logo-01.png"
						class="h-10"
						alt="Paleta"
					/>
				</div>
				<div class="divide-y divide-gray-300/50">
					<div class="space-y-6 text-base leading-7 text-gray-600">
						<h1 class="text-xl mt-4 text-center font-medium">
							Wholesale Order
						</h1>
						<div v-if="authStore.isAdmin">
							<h1 class="text-lg mt-2 text-center font-medium">
								Place order on behalf of:
							</h1>
							<CustomerSelector
								:customers="customers"
							></CustomerSelector>
						</div>

						<ul v-else class="space-y-3">
							<li class="flex items-center">
								<font-awesome-icon :icon="['fas', 'check']" />

								<p class="ml-4">
									Natural flavours and Ingredients
								</p>
							</li>
							<li class="flex items-center">
								<font-awesome-icon :icon="['fas', 'check']" />
								<p class="ml-4">
									No artificial flavours, colours or
									preservatives
								</p>
							</li>
						</ul>

						<div class="flex justify-center">
							<div class="relative mt-1">
								<div
									class="cursor-pointer flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
								>
									<font-awesome-icon
										:icon="['fas', 'magnifying-glass']"
									/>
								</div>
								<input
									z-10
									v-model="search"
									type="text"
									:class="
										search
											? 'border-orange-400 outline-none'
											: ''
									"
									class="-z-10 block p-2 pl-10 w-80 text-sm text-gray-900 bg-white rounded-xl border border-gray-300 hover:border-orange-400 focus:outline-none focus:border-orange-300 focus:ring-1 focus:ring-orange-300"
									placeholder=""
								/>
								<!-- <button
									@click="speechSearch"
									type="button"
									class="flex absolute inset-y-0 right-0 items-center pr-3"
								>
									
									<font-awesome-icon
										
										:icon="['fas', 'microphone']"
									/>

								</button> -->
							</div>
							<button
								v-show="search"
								@click="search = ''"
								class="p-2 ml-1"
							>
								<font-awesome-icon
									:icon="['fas', 'xmark']"
									class="hover:text-orange-400"
								/>
							</button>
						</div>

						<ul>
							<OrderItem
								v-for="(product, index) in products"
								:index="index"
								:key="index"
							/>
						</ul>
					</div>
				</div>
			</div>

			<label for="deliveryInstructions" class="font-medium">
				Delivery Notes</label
			>
			<textarea
				id="deliveryInstructions"
				v-model="DeilveryInstructions"
				rows="2"
				class="mb-6 mt-3 block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-200 hover:border-orange-400 focus:outline-none focus:border-orange-300 focus:ring-1 focus:ring-orange-300"
			></textarea>
		</div>
	</div>

	<div class="flex justify-center mt-1 -mb-1 md:mt-4">
		<div
			:class="{ fixed: true, absolute: false }"
			class="mx-auto px-5 bottom-2 md:bottom-20 text-base font-semibold bg-gray-100 opacity-95 py-3 md:py-4 shadow-2xl"
		>
			<div class="text-lg font-medium mx-2 my-3 md:m-5">
				Total Order: {{ TotalQty }} Bags
				<span class="text-xs">({{ TotalQty * 25 }} icypoles)</span>
				${{ TotalAmt }}
			</div>
			<button
				@click="NewOrder"
				class="mt-2 h-12 w-full rounded-md focus:outline-none text-white transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-105 hover:bg-blue-600 duration-300"
			>
				Place Orders
			</button>
		</div>
	</div>
</template>

<script>
	import { ref, watch, computed, onMounted } from 'vue'
	import { addOrder_firebase, getCustomers_firebase } from '../db/firebase'
	import { addOrder_Quickbooks } from '../db/quickbooks'
	import OrderItem from './OrderItem'
	import CustomerSelector from './CustomerSelector'
	import { storeToRefs } from 'pinia'
	import { useProductsStore } from '../stores/products'
	import { useAuthStore } from '../stores/auth'
	import { useMainStore } from '../stores/main'

	import { useToast } from 'vue-toast-notification'

	import ModalSpinner from '@/components/ModalSpinner.vue'

	import { cloudFunctionEndPoint } from '../db/config'

	export default {
		name: 'OrderForm',

		components: {
			ModalSpinner,
			OrderItem,
			CustomerSelector
		},

		setup() {
			// Products  Store
			const productsStore = useProductsStore()
			const mainStore = useMainStore()
			const authStore = useAuthStore()

			productsStore.getProducts()

			const { products, TotalQty, TotalAmt, onOrder } =
				storeToRefs(productsStore)

			const $toast = useToast()

			const DeilveryInstructions = ref()

			const search = ref('')

			const searchFilter = computed(() => {
				return search.value
			})

			watch(searchFilter, () => {
				productsStore.products.forEach((product) => {
					if (
						product.Name.toLowerCase().includes(
							search.value.toLowerCase()
						)
					) {
						product.Show = true
					} else {
						product.Show = false
					}
				})
			})

			const customers = ref([])

			onMounted(async () => {
				if (authStore.isAdmin) {
					customers.value = await getCustomers_firebase()
				}
			})

			async function NewOrder() {
				try {
					if (onOrder.value.length === 0) {
						if (!QB_Id) {
							$toast.open({
								type: 'error',
								message: `Nothing on order`,
								position: 'top'
							})
						}
						return
					}

					mainStore.Spinner_On()

					var CompanyName = null
					var QB_Id = null

					if (!authStore.isAdmin) {
						CompanyName = authStore.customerDetails.CompanyName
						QB_Id = authStore.customerDetails.QB_Id
					} else {
						CompanyName = mainStore.selectedCustomer?.CompanyName
						QB_Id = mainStore.selectedCustomer?.QB_Id

						if (!QB_Id) {
							$toast.open({
								type: 'error',
								message: `No Customer has been selected`,
								position: 'top'
							})
							return
						}
					}

					const order = {
						customerName: CompanyName,
						customerID: QB_Id,
						orderTotalQty: TotalQty.value,
						orderDateTime: new Date().toISOString(),
						orderItems: onOrder.value,
						orderDeilveryInstructions: DeilveryInstructions.value
					}

					// 1. Create Order in Quickbooks
					mainStore.QBOrder = await addOrder_Quickbooks(order)

					// 2. Create Order in Firebase
					await addOrder_firebase(
						authStore.user.uid,
						mainStore.QBOrder,
						order
					)
					productsStore.resetOrderForm()

					sendEmail()

					mainStore.ToggleOrderSuccess()
				} catch (error) {
					mainStore.OrderError('Could not create order')
					console.log(`Issue creating order: ${error}`)
					$toast.open({
						type: 'error',
						message: `Could not create order. Please contact support@paleta.com.au`,
						position: 'top'
					})
				} finally {
					DeilveryInstructions.value = ''
					mainStore.Spinner_Off()
				}
			}

			async function sendEmail() {
				if (mainStore.QBOrder) {
					try {
						// 3. Send Order Email
						mainStore.Spinner_On()
						await sendOrderConfirmationEmail(mainStore.QBOrder)
						mainStore.Spinner_Off()
					} catch (error) {
						console.log(`Issue sending email: ${error}`)
					}
				}
			}

			async function sendOrderConfirmationEmail(QBOrder) {
				try {
					// Send Email
					const response = await fetch(
						`${cloudFunctionEndPoint}/sendMail?dest=${authStore.customerDetails.EmailAddress}`,
						{
							method: 'post',
							body: JSON.stringify(QBOrder)
						}
					)
					const data = await response.text()
					console.log(
						`Success sending order confirmation email: ${JSON.stringify(
							data
						)}`
					)
				} catch (error) {
					return new Error(error)
				}
			}

			return {
				authStore,
				products,
				TotalQty,
				TotalAmt,
				DeilveryInstructions,
				search,
				NewOrder,
				customers
			}
		}
	}
</script>
