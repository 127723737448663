<template>
	<div class="relative z-10" v-if="mainStore.showOrderSuccess">
		<div
			as="template"
			enter="ease-out duration-300"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="ease-in duration-200"
			leave-from="opacity-100"
			leave-to="opacity-0"
		>
			<div
				class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			></div>
		</div>

		<div class="fixed z-10 inset-0 overflow-y-auto">
			<div
				class="flex items-center justify-center min-h-full p-4 text-center sm:p-0"
			>
				<div
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full"
					>
						<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
							<div class="w-full flex flex-row-reverse">
								<button
									type="button"
									class="shadow-sm text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
									@click="mainStore.ToggleOrderSuccess"
								>
									<font-awesome-icon
										:icon="['fas', 'xmark']"
									/>
								</button>
							</div>

							<div class="sm:flex sm:items-start">
								<div
									class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-300 sm:mx-0 sm:h-10 sm:w-10"
								>
									<font-awesome-icon
										:icon="['fas', 'check']"
									/>
								</div>
								<div
									class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
								>
									<h3
										class="text-lg leading-6 font-medium text-gray-900"
									>
										Order Placed
									</h3>
									<div class="mt-2">
										<p class="text-sm text-gray-500">
											Your order has been placed, and a
											confirmation email has been sent to
											your address.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="bg-gray-50 py-3 flex flex-col gap-4">
							<p class="m-3 leading-6 text-gray-900">
								Invoice Number:
								<span class="font-medium">
									{{ mainStore.QBOrder.Invoice?.DocNumber }}
								</span>
							</p>
							<p class="m-3 leading-6 text-gray-900">
								Order Total:
								<span class="font-medium"
									>${{
										mainStore.QBOrder.Invoice?.TotalAmt
									}}</span
								>
							</p>
							<h4
								class="m-3 text-lg leading-6 font-medium text-gray-900"
							>
								Your Email:
							</h4>
							<input
								type="text"
								class="focus:outline-none bg-gray-100 border h-10 rounded m-2 px-2"
								v-model="authStore.customerDetails.EmailAddress"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useMainStore } from '@/stores/main'
	import { useAuthStore } from '@/stores/auth'

	export default {
		name: 'OrderSuccessModal',

		setup() {
			const mainStore = useMainStore()
			const authStore = useAuthStore()

			return {
				mainStore,
				authStore
			}
		}
	}
</script>
