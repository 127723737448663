<script setup>
	import { onMounted } from 'vue'
	import NavBar from '@/components/NavBar.vue'
	import { RouterView } from 'vue-router'
	// import { getAuth } from 'firebase/auth'
	import { useAuthStore } from '@/stores/auth'

	const authStore = useAuthStore()

	onMounted(() => {
		authStore.handleAuthStateChanged()
	})
</script>

<template>
	<NavBar />

	<div
		id="app"
		class="relative min-h-screen overflow-hidden bg-gradient-to-tl from-yellow-200 to-red-100 py-2 sm:py-12"
	>
		<router-view />
	</div>
</template>

<script>
	export default {
		watch: {
			title: {
				immediate: true,
				handler() {
					document.title = 'Wholesale Orders - Paleta'
				}
			}
		}
	}
</script>
