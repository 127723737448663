<template>
	<div class="home">
		<div class="">
			<OrderSuccessModal />
			<OrderForm />
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import OrderForm from '@/components/OrderForm.vue'
	import OrderSuccessModal from '@/components/OrderSuccessModal.vue'

	export default {
		name: 'HomeView',
		components: {
			OrderForm,
			OrderSuccessModal
		}
	}
</script>
